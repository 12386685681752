<template>
    <section>
        <div class="navbar-padding" v-if="pageContent">
           <div 
            class="products__block" 
            :style="{ 'background-image' : 'url(' + imgFile(pageContent) + ')' }"
            
        >
            
                <a class="d-flex justify-content-center align-items-center h-100 flex-column">
                    <h1 class="text-white hero__title text-center">{{pageContent.name}}</h1>
                    <p class="text-uppercase text-white text-center">{{pageContent.slogan}}</p>
                </a>  
            </div>
        </div>
        <div class="p-1"  v-if="pageContent">
           
            <div class="container">
            <a name="choose_a_model"></a>
            <p class="text-white text-uppercase mt-5 prodfamily_subtitle">{{$store.state.translation.choose_a_model}}</p>
                <div class="row product_families_border">
                    <div class="col-md-4" v-for="p in pageContent.earthlings_product" :key="p.id" >
                        <ProductListItem :product="p" :image="list_image" :to="{name : 'Product_'+selectedLangCode, params:{name: p.slug}}" />
                    </div>
                </div>
            </div>
        </div>
        <div class="p-1"  v-if="pageContent">
           
            <div class="container">
                <p class="text-white text-uppercase mt-5 prodfamily_subtitle">Why {{pageContent.name}}?</p>
                <div class="row text-white">
                    <div class="col-md-4" >
                       
                    </div>
                    <div class="col-md-4" v-html="pageContent.why_column1" >
                       
                    </div>
                    <div class="col-md-4" v-html="pageContent.why_column2" >
                       
                    </div>
                </div>
            </div>
        </div>
        <div class="p-1"  v-if="familySlider">
           
            <CarouselSection :slider="familySlider" />
        </div>
        <div> <!-- SELECT MODEL -->
            <div>
                <div class="choose_model d-flex justify-content-center align-items-center flex-column">
                    <p class="text-uppercase">Design your own model</p>
                    <p>Try our configurator by schoosing a base model.</p>
                    <!-- Here comes the video -->
                    <div>
                        <img src="../assets/imgs/chair.jpg">
                    </div>
                    <div class="pt-5">
                        <a href="" class="bg-white text-uppercase text-decoration-none link d-block text-center">Choose a model</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ProductListItem from '@/components/elements/ProductListItem.vue';
import CarouselSection from '../components/carouselSection.vue';
//import HeroSliderSection from "@/components/carouselSection";

export default {
    components:{
        ProductListItem,
        CarouselSection
    },
    data(){
        return{
            pageContent: null,
            homepageSlider: null
        }
    },
    computed:{
        familySlider() {
            if (this.pageContent){
                if (this.pageContent.earthlings_product_family_image)
                {
                    var ret = {earthlings_slider_item: []}
                    this.pageContent.earthlings_product_family_image.forEach(it=>{
                        ret.earthlings_slider_item.push(it);
                    })
                    return ret;
                }
            }
            return false
        }
    },
    methods:{
        async getCurrentPageContent(){
            let _this = this
           
            const content = await _this.getPageContent()
            _this.pageContent = content[0]
            //_this.homepageSlider = _this.getProductSlider()
                
        },
        imgFile (block){
            return block.image ? this.apiUrl + '/' + block.image.path +  block.image.file_name : ""
        },
        getPageContent(){
            let _this = this

            return new Promise((resolve, reject) =>{
                _this.get('earthlings_product_family/'+this.$route.params.family, {
                    params: {
                        relations: 1,
                        relations_params:{
                            earthlings_product:{
                                relations: 1
                            }
                        }
                    }
                    },
                ).then((resp) => {        
                    resolve(resp.data.earthlings_product_family)
                }).catch((err)=>{       
                    console.log(err) 
                   
                    reject()
                })
            })
        },
        getProductSlider(){
            let slug = this.$route.params.name
            return this.pageContent.earthlings_page_block.find( item => item.name == `${slug}_slider`).earthlings_slider[0]
        }
    },
    async created(){
        await this.getCurrency();
        this.getCurrentPageContent()
    }
}
</script>